





























































































































import { Component, Vue } from "vue-property-decorator";
import { apiCompenstateLists } from "@/api/order/order";
import { RequestPaging } from "@/utils/util";
import DatePicker from "@/components/date-picker.vue";
import { AfterSaleType } from "@/utils/type";
import ExportData from "@/components/export-data/index.vue";
import AfterSalesPane from "@/components/order/after-sales-pane.vue";
import LsPagination from "@/components/ls-pagination.vue";

@Component({
    components: {
        AfterSalesPane,
        DatePicker,
        ExportData,
        LsPagination,
    },
})
export default class AfterSales extends Vue {
    // S Data
    activeName: any = "all";
    fullscreenLoading = false
    apiCompenstateLists = apiCompenstateLists;

    form: any = {
        compensate_sn: "",
        after_sale_sn: "", //否	string	售后单号
        order_sn: "", //否	string	订单号
        compensate_type: "",
        loss_for_bearer: "",
        loss_for_amount: "",
        start_time: "",
        end_time: "", //否	string	申请结束时间 格式：年月日 时：分：秒
    };

    pager = new RequestPaging();

    // E Data

    // S Methods
    // 获取订单信息
    getLists(page?: number): void {
        page && (this.pager.page = page);
        this.pager
            .request({
                callback: apiCompenstateLists,
                params: {
                    ...this.form,
                },
            })
            .then((res) => {});
    }

    // 重置搜索
    reset() {
        Object.keys(this.form).map((key) => {
            this.$set(this.form, key, "");
        });
        this.getLists();
    }

    // E Methods

    // S  life cycle

    created() {
        // 获取售后订单信息
        this.getLists();
        // 获取其他方式数据
    }

    // E life cycle
}
